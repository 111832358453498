import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { useOccurrences, createOccurrencesRef } from "../../data/useOccurrences";
import { Button } from "@material-ui/core";
import firebase from "@firebase/app";

const Root = styled.div`
  color: #777777;
  text-align: center;
  height: 130px;
  width: 130px;
  border: solid 1px grey;
  box-sizing: border-box;
  padding: 0.25rem;
  margin: -1px -1px 0 0;
  overflow: auto;
  ${({onClick}) => onClick ? css`
    cursor: pointer;
    &:hover {
      color: #000000;
    }
  ` : ""}
`;

const Phrase = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

const Badge = styled.sup`
  height: 1rem;
  min-width: 1rem;
  font-size: 0.7rem;
  color: white;
  display: inline-block;
  background-color: red;
  border-radius: 50%;
  padding: 2px 1px 0 1px;
  margin-left: 0.25rem;
  font-weight: bold;
`;

const useIsAdmin = () => true; //TODO: change to false before start

const addOccurrence = id => {
  const ref = createOccurrencesRef();
  ref.push({
    phraseId: id,
    at: firebase.database.ServerValue.TIMESTAMP
  });
}

const NoBreak = styled.span`
  white-space: nowrap;
`;

export const Cell = ({ text, id }) => {
  const occurrences = useOccurrences();
  const currentOccurrences = occurrences && occurrences.filter(o => o.phraseId === id);
  const hasOccurred = currentOccurrences && currentOccurrences[0];
  const isAdmin = useIsAdmin();
  const words = text.split(" ");
  return (
    <Root onClick={id !== "free" && isAdmin && (() => addOccurrence(id))}>
      <Phrase hasOccurred={hasOccurred}>
        {!hasOccurred && text}
        {hasOccurred && (
          <Fragment>
            {words.slice(0, words.length -1).join(" ")}{" "}
            <NoBreak>
              {words[words.length -1]}<Badge>{currentOccurrences.length}</Badge>
            </NoBreak>
          </Fragment>
        )}
      </Phrase>
    </Root>
  );
};
