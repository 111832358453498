import React from "react";
import { useOccurrences } from "../data/useOccurrences";
import TimeAgo from "react-timeago";
import styled from "styled-components";
import { phrases } from "./bingoBoard/phrases";

const Root = styled.div`
  margin: 3rem auto;
  width: 600px;
`;

export const Log = () => {
  const occurrences = useOccurrences();
  if (!occurrences || !occurrences[0]) {
    return null;
  }
  return (
    <Root>
      <h3>So far...</h3>
      <table>
        <tbody>
          {occurrences.reverse().map(o => (
            <tr key={o.at}>
              <td>
                (<TimeAgo date={o.at} />)
              </td>
              <td>{phrases.find(p => p.id === o.phraseId).text}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Root>
  );
};
