import React from "react";
import styled from "styled-components";
import { phrases } from "./phrases";
import { Cell } from "./Cell";

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem;
  max-width: 820px;
`;

export const Board = () => {
  return (
    <Root>
      {phrases.map(phrase => (
        <Cell key={phrase.id} {...phrase} />
      ))}
    </Root>
  );
};
