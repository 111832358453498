export const firebaseConfig = {
  "apiKey": "AIzaSyAm6XM0NGBZLefmvcC3cAYDg_Wnv-lDUTg",
  "appId": "1:1096847647388:web:44408620f3a1cb53510359",
  "authDomain": "drink-for.firebaseapp.com",
  "databaseURL": "https://drink-for.firebaseio.com",
  "measurementId": "G-E7460BYVCW",
  "messagingSenderId": "1096847647388",
  "projectId": "drink-for",
  "storageBucket": "drink-for.appspot.com"
};
