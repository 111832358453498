import firebase from "@firebase/app";
import "@firebase/analytics";
import "@firebase/database";

import { firebaseConfig } from "./firebaseConfig";

export const firebaseInit = () => {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
};
