import { useState, useEffect } from "react";
import firebase from "@firebase/app";

export const usePageUpdate = () => {
  const [vId, setvId] = useState(null);
  useEffect(() => {
    const db = firebase.database();
    const vIdRef = db.ref(`/vId`);
    const unsubscribe = vIdRef.on("value", doc => {
      const newVId = doc && doc.val();
      if (vId !== null && vId !== newVId) {
        return window.location.reload(true);
      }
      setvId(newVId);
    });

    return unsubscribe;
  }, [vId]);

  return vId;
};
