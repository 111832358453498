export const phrases = [
  {
    id: "exaggerates-mayor",
    text: "Exaggerates his track record as mayor of London"
  },
  {
    id: "brexit-done",
    text: "\"Get Brexit done\""
  },
  {
    id: "evades-austerity",
    text: "Evades question related to the cost of austerity"
  },
  {
    id: "asks-jc-ref",
    text: "Asks Jeremy whether he'll back leave or remain in referendum"
  },
  {
    id: "great-deal",
    text: "\"Great deal\""
  },
  {
    id: "audience-laugh",
    text: "Audience laugh at his blatant lie"
  },
  {
    id: "accuses-c-brexit-block",
    text: "Accuses Corbyn of blocking Brexit"
  },
  {
    id: "not-privatising",
    text: "Says he's not privatising the NHS"
  },
  {
    id: "no-sense",
    text: "Sentence that makes no sense"
  },
  {
    id: "denies-sell-NHS",
    text: "Denies Tory plans to sell off the NHS"
  },
  {
    id: "20-new",
    text: "Lies about 20 new hospitals"
  },
  {
    id: "lies-labour",
    text: "Lies about labour policy"
  }
];
