import React, { useRef, useEffect, useState } from "react";
import { useOccurrences } from "../data/useOccurrences";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import { phrases } from "./bingoBoard/phrases";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Notification = styled(Snackbar)`
  && {
    .MuiSnackbarContent-root {
      margin-top: 100px;
      background-color: #43a047;
      height: 100px;
    }
    .MuiSnackbarContent-message {
      text-align: center;
      font-size: 1.4rem;
    }
  }
`;

export const Notifications = () => {
  const occurrences = useOccurrences();
  const currentLength = occurrences && occurrences.length;
  const prevLength = usePrevious(currentLength);
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    if (currentLength !== prevLength) {
      setDisplay(true);
      setTimeout(() => setDisplay(false), 1500);
    }
  }, [currentLength, prevLength])
  if (!occurrences || !occurrences[0]) {
    return null;
  }
  if (!display) {
    return null
  }
  return (
    <Notification
      anchorOrigin={{vertical: "top", horizontal: "center"}}
      open={true}
      message={<span>{phrases.find(p => p.id === occurrences[0].phraseId).text}</span>}
    />
  );
};
