import React from "react";
import { Board } from "./components/bingoBoard/Board";
import {
  useFirebaseOccurrences,
  occurrencesContext
} from "./data/useOccurrences";
import { Log } from "./components/Log";
import styled from "styled-components"
import { usePageUpdate } from "./data/usePageUpdate";
import { Notifications } from "./components/Notifications";

const Title = styled.h1`
`;

function App() {
  usePageUpdate();
  const occurrences = useFirebaseOccurrences();
  return (
    <occurrencesContext.Provider value={{ occurrences }}>
      <Title>When Boris says...</Title>
      <Board />
      <Log />
      <Notifications />
    </occurrencesContext.Provider>
  );
}

export default App;
