import { createContext, useContext, useState, useEffect } from "react";
import firebase from "@firebase/app";

export const occurrencesContext = createContext({ occurrences: [] });

export const createOccurrencesRef = () => {
  const db = firebase.database();
  const speaker = "after-22-11-bojo";
  return db.ref(`speakers/${speaker}/occurrences`);
}

export const useFirebaseOccurrences = () => {
  const [occurrences, setOccurrences] = useState([]);
  useEffect(() => {
    const occurrencesRef = createOccurrencesRef();
    const unsubscribe = occurrencesRef.on("value", doc => {
      const snapshot = doc && doc.val();
      const array = snapshot && Object.values(snapshot);
      setOccurrences(array);
    });

    return unsubscribe;
  }, []);

  return occurrences;
};

export const useOccurrences = () => {
  const { occurrences } = useContext(occurrencesContext);
  return occurrences;
};
